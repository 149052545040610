import icons from './apps';

var {
  taskbar,
  desktop,
  pinned,
  recent
} = {
  taskbar: (localStorage.getItem("taskbar") &&
    JSON.parse(localStorage.getItem("taskbar"))) || ["设置", "文章资源管理器", "Microsoft Edge"],
  desktop: (localStorage.getItem("desktop") &&
    JSON.parse(localStorage.getItem("desktop"))) || ["此电脑", "回收站", "文章资源管理器", "Microsoft Store",
    "Microsoft Edge", "Github"
  ],
  pinned: (localStorage.getItem("pinned") &&
    JSON.parse(localStorage.getItem("pinned"))) || ["Microsoft Edge", "Word", "PowerPoint", "OneNote", "邮件", "To Do", "Microsoft Store",
    "照片", "Notepad++", "White Board", "计算器",
    "Twitter", "VS Code", "Windows 终端", "Github"
  ],
  recent: (localStorage.getItem("recent") &&
    JSON.parse(localStorage.getItem("recent"))) || ["邮件", "文章资源管理器", "Windows 终端", "Github", "VS Code", "Microsoft Edge"]
};

export const taskApps = icons.filter(x => taskbar.includes(x.name));

export const desktopApps = icons.filter(x => desktop.includes(x.name)).sort((a, b) => {
  return desktop.indexOf(a.name) > desktop.indexOf(b.name) ? 1 : -1;
});

export const pinnedApps = icons.filter(x => pinned.includes(x.name)).sort((a, b) => {
  return pinned.indexOf(a.name) > pinned.indexOf(b.name) ? 1 : -1;
});

export const recentApps = icons.filter(x => recent.includes(x.name)).sort((a, b) => {
  return recent.indexOf(a.name) > recent.indexOf(b.name) ? 1 : -1;
});

export const allApps = icons.filter(app => {
  return app.type === 'app'
});

export const dfApps = {
  taskbar,
  desktop,
  pinned,
  recent
}
