import React, {useState} from 'react';
import ReactMarkdown from "react-markdown"
// import "./../node_modules/antd/dist/antd.css"
import MarkdownNavbar from "markdown-navbar";
import { useParams } from "react-router-dom";
import './../node_modules/markdown-navbar/dist/navbar.scss';
import {Prism as SyntaxHighlighter} from 'react-syntax-highlighter'
import {dark} from 'react-syntax-highlighter/dist/esm/styles/prism'
// import style from './article.scss';
// import { Layout,Anchor  } from 'antd';
import CodeBlock from './utils/codeBlock'
import request from 'superagent';
import remarkGfm from 'remark-gfm'

var articleDic = {}
var article = ''

function Article() {
    
    document.body.style.width = "auto";
    document.body.style.background = "#f0f0f0";
    document.body.style.fontSize= "18px";
    // document.body.style.overflow = "visible !important";
    document.body.style.setProperty('overflow', 'visible', 'important');
    
    const [value, setValue] = useState(1);
    const style = require('./article.scss')

    let params = useParams();
    request.post('https://www.windows11fake.com/services/article/get')
    .set('Content-Type', 'application/json')
    .send({name : params.name})
    .end(function(err, res){
        articleDic = res.body.data;
        article = articleDic.content
        setValue(article)
    });
  
    return (
        <div className="App-main">
            <div className="markdown-body">
                <ReactMarkdown children={article} 
                components={{
                    code({node, inline, className, children, ...props}) {
                      const match = /language-(\w+)/.exec(className || '')
                      return !inline && match ? (
                        <SyntaxHighlighter
                          children={String(children).replace(/\n$/, '')}
                          style={dark}
                          language={match[1]}
                          PreTag="div"
                          {...props}
                        />
                      ) : (
                        <code className={className} {...props}>
                          {children}
                        </code>
                      )
                    }
                  }}
                remarkPlugins={[remarkGfm]} renderers={{code: CodeBlock}}/>
                <div className="h-10"></div>
            </div>
            <div style={{height: '60px'}}></div>
            <div className="nav-container show">
                <MarkdownNavbar source={article}></MarkdownNavbar>
            </div>
        </div>
    )
}

export default Article;