import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Icon, Image, ToolBar } from "../../../utils/general";

export const AboutWin = () => {
  const { abOpen } = useSelector((state) => state.desktop);
  const { locked, booted } = useSelector((state) => state.wallpaper);
  const [open, setOpen] = useState(true && process.env.REACT_APP_ENV !== "development");
  const [timer, setTimer] = useState(localStorage.getItem("closeAbout") === "true" ? 0 : 6);
  const dispatch = useDispatch();

  const action = () => {
    setOpen(false);
    localStorage.setItem("closeAbout", true);
    dispatch({ type: "DESKABOUT", payload: false });
  };

  useEffect(() => {
    if (timer > 0 && !locked && booted) {
      setTimeout(() => {
        setTimer(timer - 1);
      }, 1000);
    }
  }, [timer, locked, booted]);

  const fullScreen = () => {
    var element = document.documentElement;  //获取界面root元素
    if (element.requestFullscreen) {
        element.requestFullscreen();
    } else if (element.mozRequestFullScreen) {   // 兼容火狐
        element.mozRequestFullScreen();
    } else if (element.webkitRequestFullscreen) {    // 兼容谷歌
        element.webkitRequestFullscreen();
    } else if (element.msRequestFullscreen) {   // 兼容IE
        element.msRequestFullscreen();
    }
  }

  return open || abOpen ? (
    <div className="aboutApp floatTab dpShad">
      <div className="py-1 aboutTop px-2 text-xss">
        <div className="">通知</div>
      </div>
      <div className="windowScreen" data-dock="true">
        <div className="restWindow h-full flex flex-col items-center p-4">
          <div className="abCont"> 
            <div>OS (fake Windows)</div>
            <div>Version 11</div>
            <br />
            <div>
              本博客旨在仿制<mark> Windows 11 </mark>
              桌面版本的 Web 体验，需在桌面浏览器上完整体验所有功能。
              <br />
              <br />
              项目采用 ReactJS，创意与原型来源于 win11React
              {/* <a target="_blank" href="" rel="noreferrer">
              win11React
              </a> */}
              。
            </div>
            <br />
            <div>
              博客文章内容储存在<mark> 文章资源管理器 </mark> 中，行为逻辑参考 Windows 桌面系统操作习惯，具体使用方式请自行摸索…
            </div>
            <br />
            <br />
            <br />
              博客纯属个人喜好，不考虑使用浏览繁琐度和传播性。
            <br />
            <div className="mt-1">
             要联系博主，请发送至邮箱:{" "}
              <a target="_blank" href="mailto:boll12138@gmail.com" rel="noreferrer">
                boll12138@gmail.com
              </a>
            </div>
            <br />
            <br />网站已备案：
            <a href="https://beian.miit.gov.cn/" target="_blank">@ 赣ICP备2021011232号</a>
            <div className="okbtn">
              <div data-allow={timer === 0} onClick ={() => {timer === 0 && action(), fullScreen()}}>
                我已了解 {timer > 0 ? <span>{"(" + timer + ")"}</span> : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : null;
};
