import React, {useState, useEffect} from 'react';
import {Divider } from "antd"
import {useSelector, useDispatch} from 'react-redux';
import {Icon, Image, ToolBar} from '../../../utils/general';
import "./../../../../node_modules/antd/dist/antd.css"
import "./assets/explorer.scss"
import dirs from './assets/dir.json';
import { DownOutlined } from '@ant-design/icons';
import request from 'superagent';

var articleDic = {parentDirectory: [], peerDirectory: []}
var pathIdList = [1, 2]
var history = 0
var queryDic = {
  filePath: null,
  documentId: null,
  fileName: null,
  sort: 'name',
  parentId: null
}

function browserFolders (key){
  queryDic.parentId=key
  request.post('https://www.windows11fake.com/services/article/list')
  .set('Content-Type', 'application/json')
  .send(queryDic)
  .end(function(err, res){
    articleDic = res.body.data;
  });
}

browserFolders(3)

export const Explorer = ()=>{
  const apps = useSelector(state => state.apps);
  const wnapp = useSelector(state => state.apps.explorer);
  const [dpath, setPath] = useState("");
  const [value, setValue] = useState(1);

  const dispatch = useDispatch();

  const clickDispatch = (event) => {
    var action = {
      type: event.target.dataset.action,
      payload: event.target.dataset.payload,
    };

    if (action.type) {
      dispatch(action);
    }

    if (action.type && (action.payload == "full" || action.type == "EDGELINK")) {
      dispatch({
        type: "STARTHID",
      });
    }

    if (action.type == "STARTALPHA") {
      var target = document.getElementById("char" + action.payload);
      if (target) {
        target.parentNode.scrollTop = target.offsetTop;
      } else {
        var target = document.getElementById("charA");
        target.parentNode.scrollTop = 0;
      }
    }
  };

  const clickHistoryPath = () =>{
    if(history > 2){
      const tmpHistory = history + 1 -1;
      history = 0
      return <Icon className="hoverBlue"  src="win/shiftRight" onClick={() => {browserFoldersRefresh(tmpHistory)}} width={25} margin="0 5px"/>
    }else{
      return <Icon className="hoverBlue"  src="win/shiftRightGray"  width={25} margin="0 5px"/>
    }
  }

  const browserFoldersRefresh = (key) =>{
    queryDic.parentId=key
    request.post('https://www.windows11fake.com/services/article/list')
    .set('Content-Type', 'application/json')
    .send(queryDic)
    .end(function(err, res){
      articleDic = res.body.data;
      pathIdList=[]
      articleDic.parentDirectory.map(articleObj=>{
        pathIdList.push(articleObj.id)
      })
      setValue(key)
    });
  }

  const backPath = () =>{
    const [last, index] = [...pathIdList].reverse()
    // console.log(last, index)
    if (index > 2){
      return (
        <div className="flex items-center">
          <Icon className="hoverBlue"  src="win/shiftLeft" onClick={() => {browserFoldersRefresh(index), history = last}} width={25} margin="0 5px"/>
          {clickHistoryPath()}
          <Icon className="hoverBlue" src="win/downGray" width={25} margin="0 5px"/>
          <Icon className="hoverBlue" src="win/arrowup" onClick={() => {browserFoldersRefresh(index), history = last}} width={20} margin="0 5px"/>
        </div>
      )
    }else {
      return (
        <div className="flex items-center">
          <Icon className="hoverBlue"  src="win/shiftLeftGray" width={25} margin="0 5px"/>
          {clickHistoryPath()}
          <Icon className="hoverBlue" src="win/downGray" width={25} margin="0 5px"/>
          <Icon className="hoverBlue" src="win/arrowupGray" width={20} margin="0 5px"/>
        </div>
      )
    } 
  }

  const getFileIcon = (key) =>{
    switch (key.type){
      case '文件夹' :
        return (
          <div key={key.id} className="w-full h-10 flex">    
            <div onDoubleClick={() => { browserFoldersRefresh(key.id); } } className="hoverBlue w-4/6 flex">
              <div className="w-3/6 flex items-center h-5 ml-2 mt-2">
              <Icon src='win/10' width={20} margin='0 5px 0 10px' />
                {key.name}
              </div>
              <span className="w-1/6 flex items-center h-5  mt-2">
                {key.updateTime}
              </span>
              <span className="w-1/6 flex items-center h-5  mt-2">
                {key.type}
              </span>
              <span className="w-1/6 flex items-center h-5  mt-2">
              </span>
            </div>
          </div>
        )
      
      case 'HTML 文档':
        return (
            <div key={key.id} value="true"
              onDoubleClick={clickDispatch} data-action="EDGELINK"
              data-payload={'https://www.windows11fake.com/article/' + key.name} className="hoverBlue w-4/6 h-10 flex">
              <div value="true"
              onDoubleClick={clickDispatch} data-action="EDGELINK"
              data-payload={'https://www.windows11fake.com/article/' + key.name} className="w-3/6 flex items-center h-5 ml-2 mt-2">
                <Icon src={'edge'} width={20} margin='0 5px 0 10px'/>
                {key.name}
              </div>
              <div value="true"
              onDoubleClick={clickDispatch} data-action="EDGELINK"
              data-payload={'https://www.windows11fake.com/article/' + key.name} className="w-1/6 flex items-center h-5  mt-2">
                {key.updateTime}
              </div>
              <div value="true"
              onDoubleClick={clickDispatch} data-action="EDGELINK"
              data-payload={'https://www.windows11fake.com/article/' + key.name} className="w-1/6 flex items-center h-5  mt-2">
                {key.type}
              </div>
              <div value="true"
              onDoubleClick={clickDispatch} data-action="EDGELINK"
              data-payload={'https://www.windows11fake.com/article/' + key.name} className="w-1/6 flex items-center h-5  mt-2">
                {key.size + ' K'}
              </div>
            </div>
        )
    }
  }
  // browserFolders(setValue, 3);
  // const dispatch = useDispatch();
  // const forceUpdate = useForceUpdate();
  
  return (
    <div className="msfiles floatTab dpShad" data-size={wnapp.size}
      data-max={wnapp.max} style={{
        ...(wnapp.size==="cstm"?wnapp.dim:null),
        zIndex: wnapp.z
      }} data-hide={wnapp.hide} id={wnapp.icon+"App"}>
      <ToolBar app={wnapp.action} icon={wnapp.icon} size={wnapp.size}
        name="文章资源管理器"/>
        <div className="menuToolBar">
          <div className="toolbaeMenu flex">
            <div className="flex items-center ml-6 ">
              <Icon className='menuopt2' src='win/plus2' width={20} />
              &emsp;
                  <a className="ant-dropdown-link" style={{color:'gray'}} onClick={e => e.preventDefault()}>
                    新建&nbsp;<DownOutlined />
                  </a>
                &emsp;
                <Divider span={1} type="vertical" style={{height:'35px'}}/>
            </div>
            <div className="flex  items-center ml-5 mr-1 ">
              <Icon src='win/cut2' width={20} />
            </div>
            <div className="flex  items-center ml-8 mr-1 ">
              <Icon src='win/copy2' width={20} />
            </div>
            <div className="flex items-center ml-8 mr-1 ">
              <Icon src='win/paste2' width={20} />
            </div>
            <div className="flex items-center ml-8 mr-1 ">
              <Icon src='win/shared2' width={20} />
            </div>
            <div className="flex items-center ml-8 ">
              <Icon src='win/delete2' width={20} />
            </div>
            <div className="flex items-center ml-4">
              <Divider span={1} type="vertical" style={{height:'35px'}}/>
            </div>
            <div className="menuopt2 flex items-center ml-1 relative">
              &emsp;
              <Icon src='win/show' width={20} />
              &emsp;
              <a className="ant-dropdown-link" style={{color:'black'}} onClick={e => e.preventDefault()}>
              排序&nbsp;<DownOutlined />
              &nbsp;&nbsp;
              </a>
              <div className="minimenu2 flex  items-center">
                <p className="ml-4 h-6 ">●&nbsp;&nbsp;名称</p>
                <p className="ml-4 h-6">&nbsp;&nbsp;&nbsp; 修改时间</p>
                <p className="ml-4 h-6 ">&nbsp;&nbsp;&nbsp; 类型</p>
              </div>
            </div>
            <div className="menuopt2 flex items-center ml-5 relative">
              <Icon margin="0 0 0 10px" src='win/menu' width={20} />
                  &emsp;
                  <a className="ant-dropdown-link" style={{color:'black'}} onClick={e => e.preventDefault()}>
                  查看&nbsp;<DownOutlined />
                  </a>
                &emsp;
                <div className="minimenu2 flex  items-center">
                <p className="ml-4 h-6 ">&nbsp;&nbsp;&nbsp; 大图标</p>
                <p className="ml-4 h-6">&nbsp;&nbsp;&nbsp; 小图标</p>
                <p className="ml-4 h-6 ">●&nbsp;&nbsp;详细信息</p>
              </div>
            </div>
            <Divider span={1} type="vertical" style={{height:'35px'}}/>
            <div className="menuopt2 flex items-center ml-4 mr-1 relative">
              <Icon src='win/more' margin="0 10px 0 10px" width={20} />
            </div>
          </div>
        </div>
      <div className="windowScreen flex flex-col" data-dock="true">
          <div className="restWindow flex-grow flex flex-col"  style={{background: '#ffffff'} }>
            <div className="fileAddressBar w-full mt-2 mb-2 ml-2 h-10 flex items-center">
              {backPath()}
              <div className="addContMaxWidth flex relative">
                <div className="borderDiv w-5/6 h-10 ml-3 flex items-center relative"> 
                    <Icon src="win/10" width={20} margin="0 0 0 10px"/>
                    {articleDic.parentDirectory.map(key=>{
                      let pathButton;
                      if (key.id >2){
                        pathButton = <div className="hoverBlue h-full flex items-center" onClick={() => {browserFoldersRefresh(key.id)}} >{key.name}</div>;
                      } else {
                        pathButton = <div className="h-full flex items-center" >{key.name}</div>;
                      }
                      return(
                        <div key={key.id} className="h-full flex items-center">
                          <Icon src="win/right2" width={20} />
                          {pathButton}
                        </div>
                      )
                    })}
                    <Icon src="win/right2" width={20}/>
                    <div className=" flex items-center"  style={{position: 'absolute', right: '0px'}}>
                      <Icon src="win/down" width={20}/>
                      <Icon src="win/refresh" width={20} margin="0 10px 0 0"/>
                    </div>
             
                </div>
                <div className="w-1/6 h-10 ml-2 mr-2 flex" >
                  <div className="borderDiv w-full h-10 text-gray flex items-center">
                    <Icon src="win/rightSearch" width={15} margin="0 10px 0 10px"/>
                    <input
                    // className="w-full h-6 px-4"
                    placeholder="搜索'文章资源管理器'"
                    type="text"
                    style={{borderStyle: 'none'}}/>
                    
                  </div>
                  &nbsp;&nbsp;
                </div>
              </div>
            </div>
            <div className="msfilesBody flex">
              <div className="w-50">
                <div className="hoverBlue flex items-center h-6 ml-2 mt-4">
                  <Icon src='win/right2' width={20} />
                  <Icon src='win/1661' width={20} margin='0 5px 0 5px'/>
                  快速访问
                </div>
                <div className="hoverBlue flex items-center h-6 ml-2 mt-4">
                  <Icon src='win/right2' width={20} />
                  <Icon src='oneDrive' width={20} margin='0 5px 0 5px'/>
                  OneDriver
                </div>
                <div className="hoverBlue flex items-center h-6 ml-2 mt-4">
                  <Icon src='win/right2' width={20} />
                  <Icon src='win/917' width={20} margin='0 5px 0 5px'/>
                  此电脑
                </div>
                <div className="hoverBlue flex items-center h-6 ml-2 mt-4">
                  <Icon src='win/right2' width={20} />
                  <Icon src='win/183' width={20} margin='0 5px 0 5px'/>
                  网络
                </div>
              </div>
              <Divider span={1} type="vertical" style={{height:'95%'}}/>
              <div className="w-full h-full">
                <div className="w-4/6 h-10 mb-2 flex">
                  <div className="hoverBlue w-3/6 flex items-center h-7 ml-2 mt-2 relative">
                    <p className="ml-5 mt-2">名称</p>
                    <div className=" flex items-center"  style={{position: 'absolute', right: '0px'}}>
                      <Divider className="hoverMenu" span={1} type="vertical" style={{height:'20px'}}/>
                      <Icon className="hoverMenu" src='win/down2' width={8} />
                      <Divider span={1} type="vertical" style={{height:'20px'}}/>
                    </div>
                  </div>
                  <div className="hoverBlue w-1/6 flex items-center h-7  mt-2 relative">
                    修改日期
                    <div className=" flex items-center"  style={{position: 'absolute', right: '0px'}}>
                      <Divider className="hoverMenu" span={1} type="vertical" style={{height:'20px'}}/>
                      <Icon className="hoverMenu" src='win/down2' width={8} />
                      <Divider span={1} type="vertical" style={{height:'20px'}}/>
                    </div>
                  </div>
                  <div className="hoverBlue w-1/6 flex items-center h-7  mt-2 relative">
                    类型
                    <div className=" flex items-center"  style={{position: 'absolute', right: '0px'}}>
                      <Divider className="hoverMenu" span={1} type="vertical" style={{height:'20px'}}/>
                      <Icon className="hoverMenu" src='win/down2' width={8} />
                      <Divider span={1} type="vertical" style={{height:'20px'}}/>
                    </div>
                  </div>
                  <div className="hoverBlue w-1/6 flex items-center h-7  mt-2 relative">
                    大小
                    <div className=" flex items-center"  style={{position: 'absolute', right: '0px'}}>
                      <Divider className="hoverMenu" span={1} type="vertical" style={{height:'20px'}}/>
                      <Icon className="hoverMenu" src='win/down2' width={8} />
                      <Divider span={1} type="vertical" style={{height:'20px'}}/>
                    </div>
                  </div>
                </div>
                {articleDic.peerDirectory.map(key=>{
                  return(
                    getFileIcon(key)
                  )
                })}
              </div>
            </div>
            <div className="w-full flex items-center ml-3 mb-1 h-8 relative">
                {articleDic.peerDirectory.length} 个项目
                <div className="flex items-center"  style={{position: 'absolute', right: '20px'}}>
                  <Icon className="deafulBlue" src='win/menu' margin="5px" width={20} />
                  <Icon className="hoverBlue" src='win/computer' margin="5px" width={20} />
                </div>
            </div>
        </div>
      </div>
    </div>
  );
}
