import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import App from './App';
import Article from './Article';
import ReactDOM from 'react-dom';
import store from './reducers';
import {Provider} from 'react-redux';
// import Edit from './Edit';

const BasicRoute = () => (
    <Router>
        <Routes>
            <Route path="/" element={<Provider store={store}><App /></Provider>}/>
            <Route path="/article" element={<Article/>}>
                <Route path=":name" element={<Article/>} />
            </Route>
        </Routes>
    </Router>
);

export default BasicRoute;