export const gene_name = () => Math.random().toString(36).substring(2, 10).toUpperCase()

var installed = localStorage.getItem("installed")
if (!installed) installed = "[]"

installed = JSON.parse(installed)

var apps = [{
  name: 'Start',
  icon: 'home',
  type: 'action',
  action: 'STARTMENU'
},
{
  name: 'Search',
  icon: 'search',
  type: 'action',
  action: 'SEARCHMENU'
},
{
  name: 'Widget',
  icon: 'widget',
  type: 'action',
  action: 'WIDGETS'
},
{
  name: '设置',
  icon: 'settings',
  type: 'app'
},
{
  name: '文章资源管理器',
  icon: 'explorer',
  type: 'app',
  action: 'EXPLORER'
},
{
  name: 'Microsoft Edge',
  icon: 'edge',
  type: 'app',
  action: 'MSEDGE'
},
{
  name: 'Microsoft Store',
  icon: 'store',
  type: 'app',
  action: 'WNSTORE'
},
{
  name: '回收站',
  icon: 'bin0',
  type: 'app'
},
{
  name: '此电脑',
  icon: 'win/userDir',
  type: 'short'
}, {
  name: '时钟',
  icon: 'alarm',
  type: 'app'
},
{
  name: '计算器',
  icon: 'calculator',
  type: 'app',
  action: 'CALCUAPP'
},
{
  name: '日历',
  icon: 'calendar',
  type: 'app'
},
{
  name: '相机',
  icon: 'camera',
  type: 'app',
  action: 'CAMERA'
},
{
  name: 'VS Code',
  icon: 'code',
  type: 'app',
  action: 'VSCODE'
},
{
  name: 'Excel',
  icon: 'excel',
  type: 'app'
},
{
  name: '你的手机',
  icon: 'yphone',
  type: 'app'
},
{
  name: '反馈中心',
  icon: 'feedback',
  type: 'app'
},
{
  name: '入门',
  icon: 'getstarted',
  type: 'app'
},
{
  name: 'Groove 音乐',
  icon: 'groove',
  type: 'app'
},
{
  name: '帮助',
  icon: 'help',
  type: 'app'
},
{
  name: 'Yammer',
  icon: 'yammer',
  type: 'app'
},
{
  name: '邮件',
  icon: 'mail',
  type: 'app',
  action: 'EXTERNAL',
  payload: 'mailto:blueedgetechno@gmail.com'
},
{
  name: '电影和电视',
  icon: 'movies',
  type: 'app'
},
{
  name: 'Xbox',
  icon: 'xbox',
  type: 'app'
},
{
  name: 'Office',
  icon: 'msoffice',
  type: 'app'
},
{
  name: '讲述人',
  icon: 'narrator',
  type: 'app'
},
{
  name: 'Microsoft News',
  icon: 'news',
  type: 'app'
},
{
  name: 'Notepad++',
  icon: 'notepad',
  type: 'app',
  action: 'NOTEPAD'
},
{
  name: 'Sticky Notes',
  icon: 'notes',
  type: 'app'
},
{
  name: 'OneDrive',
  icon: 'oneDrive',
  type: 'app'
},
{
  name: 'OneNote',
  icon: 'onenote',
  type: 'app'
},
{
  name: 'Outlook',
  icon: 'outlook',
  type: 'app'
},
{
  name: 'People',
  icon: 'people',
  type: 'app'
},
{
  name: '照片',
  icon: 'photos',
  type: 'app'
},
{
  name: 'PowerPoint',
  icon: 'powerpoint',
  type: 'app'
},
{
  name: 'Windows 安全中心',
  icon: 'security',
  type: 'app'
},
// {
//   name: 'Spotify',
//   icon: 'spotify',
//   type: 'app',
//   action: 'SPOTIFY'
// },
{
  name: '分享',
  icon: 'share',
  type: 'app'
},
{
  name: 'Skype',
  icon: 'skype',
  type: 'app'
},
{
  name: '截图工具',
  icon: 'snip',
  type: 'app'
},
{
  name: 'Twitter',
  icon: 'twitter',
  type: 'app',
  action: 'EXTERNAL',
  payload: 'https://twitter.com/Boll09951211'
},
{
  name: 'Teams',
  icon: 'teams',
  type: 'app'
},
{
  name: 'Windows 终端',
  icon: 'terminal',
  type: 'app',
  action: 'TERMINAL'
},
{
  name: 'Tips',
  icon: 'tips',
  type: 'app'
},
{
  name: 'To Do',
  icon: 'todo',
  type: 'app'
},
{
  name: '地图',
  icon: 'maps',
  type: 'app'
},
{
  name: '录音机',
  icon: 'voice',
  type: 'app'
},
{
  name: '天气',
  icon: 'weather',
  type: 'app'
},
{
  name: 'Word',
  icon: 'winWord',
  type: 'app'
},
{
  name: 'White Board',
  icon: 'board',
  type: 'app',
  action: 'WHITEBOARD'
},
{
  name: 'Cortana',
  icon: 'cortana',
  type: 'app'
},
{
  name: 'Github',
  icon: 'github',
  type: 'app',
  action: 'EXTERNAL',
  payload: 'https://github.com/Boll12138'
},
{
  name: 'Discord',
  icon: 'discord',
  type: 'app',
  action: 'DISCORD',
}]

for (var i = 0; i < installed.length; i++) {
  installed[i].action = gene_name()
  apps.push(installed[i])
}

export default apps
